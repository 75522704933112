type UseUiState<M, S> = M & { [K in keyof S]: ComputedRef<S[K]> }
interface AsfUIState {
  isSearchOpen: boolean
  isHamburgerMenuOpen: boolean
  isLoginModalOpen: boolean
  isFilterSidebarOpen: boolean
  isCartLoading: boolean
  lastFocusedItem: string
}
interface AsfUIStateMethods {
  toggleSearch: () => void
  toggleLoginModal: () => void
  toggleHamburgerMenu: () => void
  toggleFilterSidebar: () => void
  setCartLoading: (loading?: boolean) => void
  setlastFocusedItem: (filterKey?: string) => void
}

let state: AsfUIState

export const useUiState = (): UseUiState<AsfUIStateMethods, AsfUIState> => {
  state =
    state ||
    reactive({
      isSearchOpen: false,
      isHamburgerMenuOpen: false,
      isLoginModalOpen: false,
      isFilterSidebarOpen: false,
      isCartLoading: false,
      lastFocusedItem: ''
    })
  const isSearchOpen = computed(() => state.isSearchOpen)
  const toggleSearch = () => {
    state.isSearchOpen = !state.isSearchOpen
  }

  const isHamburgerMenuOpen = computed(() => state.isHamburgerMenuOpen)
  const toggleHamburgerMenu = () => {
    state.isHamburgerMenuOpen = !state.isHamburgerMenuOpen
  }

  const isLoginModalOpen = computed(() => state.isLoginModalOpen)
  const toggleLoginModal = () => {
    state.isLoginModalOpen = !state.isLoginModalOpen
  }

  const isFilterSidebarOpen = computed(() => state.isFilterSidebarOpen)
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen
  }

  const isCartLoading = computed(() => state.isCartLoading)
  const setCartLoading = (loading = false) => {
    state.isCartLoading = loading
  }

  const lastFocusedItem = computed(() => state.lastFocusedItem)
  const setlastFocusedItem = (idKey = '') => {
    state.lastFocusedItem = idKey
  }

  return {
    isSearchOpen,
    isCartLoading,
    isLoginModalOpen,
    isHamburgerMenuOpen,
    isFilterSidebarOpen,
    lastFocusedItem,
    toggleSearch,
    toggleLoginModal,
    toggleHamburgerMenu,
    toggleFilterSidebar,
    setCartLoading,
    setlastFocusedItem
  }
}
